import React from "react";
import Layout from "../components/Layout";
import RightColumn from "../components/RightColumn";

export default class AboutUsPage extends React.Component {
  render() {
    return (
      <Layout sidebarImageUrl="/images/photo10.jpg" sidebarImageAlt="" metaTitle="Page Not Found">
        <div id="mainContent">
          <p>
            The requested page could not be found. Please try again or
            click&nbsp;<a href="/">here</a>&nbsp;to return to the home page.
          </p>
        </div>

        <RightColumn />
      </Layout>
    );
  }
}
